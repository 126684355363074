<template>
  <div>
    <h2 class="section-header">
      <img src="@/assets/images/logs-black.svg" alt="wallet" />
      Points log
    </h2>

    <CardsOverflow>
      <div class="point-card">
        <p>Points from direct commision</p>
        <div class="point-value">
          <p class="amount">{{ toTwoDecimal(directPoints) }}</p>
          <p class="text">Points</p>
        </div>
      </div>

      <div class="point-card">
        <p>Points from team commission</p>
        <div class="point-value">
          <p class="amount">{{ toTwoDecimal(indirectPoints) }}</p>
          <p class="text">Points</p>
        </div>
      </div>

      <div class="point-card">
        <p>Total points</p>
        <div class="point-value">
          <p class="amount">{{ toTwoDecimal(totalPoints) }}</p>
          <p class="text">Points</p>
        </div>
      </div>
    </CardsOverflow>

    <section class="section-two">
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Points</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="log in pointsLog" :key="log.id">
              <td>{{ log.description }}</td>
              <td>{{ toFourDecimal(log.amount) }}</td>
              <td>{{ formatDate(log.created_at) }}</td>
            </tr>
          </tbody>
        </table>
        <div
          class="empty"
          v-if="pointsLog.length === 0 && fetchData === 'loaded'"
        >
          No Records
        </div>
        <div class="loading-container" v-if="fetchData === 'loading'">
          <Loader />
        </div>
      </div>

      <InviteFriends />
    </section>
  </div>
</template>

<script>
import CardsOverflow from "./Layout/CardsOverflow";
import InviteFriends from "./InviteFriends.vue";
import Loader from "./Loader";

import points from "../services/points";
import formatDate from "../helpers/extractDate";
import CF from "../helpers/currencyFormatter";

export default {
  name: "PointLogs",
  components: {
    CardsOverflow,
    InviteFriends,
    Loader
  },
  data() {
    return {
      directPoints: 0,
      indirectPoints: 0,
      totalPoints: 0,
      pointsLog: [],
      fetchData: ""
    };
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    toTwoDecimal(value) {
      return CF.toTwoDecimal(value);
    },
    toFourDecimal(value) {
      return CF.toFourDecimal(value);
    }
  },
  created() {
    let userID = this.$store.getters.getUser.id;
    this.fetchData = "loading";

    points
      .pointDetails(userID)
      .then(data => {
        if (data.success) {
          this.directPoints = data.direct;
          this.indirectPoints = data.indirect;
          this.totalPoints = data.total;
          this.pointsLog = data.pointslog;
        } else {
          this.$toast.error(data.error);
        }
      })
      .catch(() => {
        this.$toast.error("please check your network and refresh the page");
      })
      .finally(() => {
        this.fetchData = "loaded";
      });
  }
};
</script>

<style scoped>
@import "../styles/table.css";
@import "../styles/section-header.css";

.point-card {
  background: #fff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 314px;
  margin-right: 30px;
}
.point-card:last-of-type {
  margin-right: 0;
}

.point-value {
  padding: 2rem 0;
}
.point-card p {
  text-align: center;
  font-size: 18px;
  line-height: 1.9;
  /* padding: 24px 0 18px; */

  font-weight: 600;
}
.point-card > p {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.point-value .amount {
  padding: 0;
  font-size: 36px;
  line-height: 1;
  font-family: "Gotham-Book";
  margin-top: 29px;
}

.section-two {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
/* table styling */
.table-container {
  width: 60%;
  margin-right: 20px;
  min-width: 500px;
  height: fit-content;
}
/* table container styling ended */
@media (max-width: 991.98px) {
  .point-card {
    width: 280px;
    margin-right: 30px;
  }
  .point-card p {
    font-size: 16px;
  }
  .point-card > p {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .point-value .amount {
    font-size: 30px;
  }

  .table-container {
    margin-right: 0px;
  }
}
@media (max-width: 575.98px) {
  .point-card {
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .table-container {
    min-width: unset;
    width: 100%;
  }
}
</style>
